import { useReducer } from 'react';
import { searchPageApi } from 'api/searchPageApi';
import type {
  Ad,
  IDFP,
  IGetAdsRequestFilter,
  IGetAdsRequestRange,
  ISearchPageAds,
  ISearchPagePaging,
  ISearchPageSeoData,
  SearchPageBreadcrumbs,
} from 'api/types/searchPageApiTypes';
import type { IGetAdsRequest } from 'api/types/searchPageApiTypes';
import { GTMEvent } from 'services/gtmService';
import type { GeoFilter } from 'types';

import { MakeModelType } from 'features/filters/components/MakeModel/MakeModel.typed';
import {
  mapFiltersRequest,
  mapGeoFilterRequest,
  mapLegacyAreaFilterValues,
  mapRangesRequest,
} from 'features/filters/Filters.mapper';
import { rg4js } from 'helpers/raygun';
import { PAGE } from 'helpers/pages';
import { API_CLIENT_TIMEOUT } from 'utils';

export enum ACTION_TYPE {
  SET_STATE = 'SET_STATE',
  SET_LOADING = 'SET_LOADING',
  SET_ADS = 'SET_ADS',
  SET_ERROR = 'SET_ERROR',
  RESET = 'RESET',
}

export type AdsType = ISearchPageAds[] | [];
export type SeoDataType = ISearchPageSeoData;
export type ErrorType = Error | null;

type SetLoadingType = {
  type: ACTION_TYPE.SET_LOADING;
  payload: boolean;
};

type SetAdsType = {
  type: ACTION_TYPE.SET_ADS;
  payload: {
    ads?: AdsType;
    adsV2?: Ad[];
    spotlights?: AdsType;
    spotlightsV2?: Ad[];
    seoData?: SeoDataType;
    dfp: IDFP;
    gtm: GTMEvent;
  };
};

type SetErrorType = {
  type: ACTION_TYPE.SET_ERROR;
  payload: ErrorType;
};

type StateType = {
  isLoading: boolean;
  error: ErrorType;
  ads?: AdsType;
  adsV2?: Ad[];
  spotlights?: AdsType;
  spotlightsV2?: Ad[];
  seoData?: SeoDataType;
  dfp: IDFP;
  gtm: GTMEvent;
};

type ActionType = SetLoadingType | SetAdsType | SetErrorType;

export const useAdsReducer = (state: StateType, action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPE.SET_LOADING: {
      return {
        ...state,
        isLoading: payload as boolean,
      };
    }
    case ACTION_TYPE.SET_ADS: {
      const { ads, adsV2, spotlights, spotlightsV2, seoData, dfp, gtm } =
        payload as StateType;
      return {
        isLoading: false,
        ads,
        adsV2,
        spotlights,
        spotlightsV2,
        error: null,
        seoData,
        dfp,
        gtm,
      };
    }
    case ACTION_TYPE.SET_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: payload as ErrorType,
      };
    }
    default:
      return state;
  }
};

export const mapMakeModelFilters = (makeModelFilters: MakeModelType) => {
  let response: { make: string; model: string; trim: string }[] = [];
  makeModelFilters.forEach((item) => {
    if (!item.makeValue || item.makeValue === 'All Makes') return;
    if (item.modelValue.length > 0) {
      item.modelValue.forEach((model) => {
        response.push({
          make: item.makeValue,
          model: model,
          trim: '',
        });
      });
    } else {
      response.push({
        make: item.makeValue,
        model: '',
        trim: '',
      });
    }
  });
  return response;
};

export type UseAdsType = StateType & {
  setLoading: (loading: boolean) => void;
  setAds: (
    ads: AdsType,
    adsV2: Ad[] | undefined,
    spotlights: AdsType | undefined,
    spotlightsV2: Ad[] | undefined,
    seoData: SeoDataType | undefined,
    dfp: IDFP,
    gtm: GTMEvent,
  ) => void;
  setError: (error: ErrorType) => void;
  fetchAds: (
    filters: IGetAdsRequestFilter[],
    ranges: IGetAdsRequestRange[],
    makeModel: MakeModelType,
    from: number,
    pageSize: number,
    section: string,
    search: string,
    sort: string,
    userId: string | string[] | undefined,
    onStart: () => void,
    onSuccess: (
      breadcrumbs: SearchPageBreadcrumbs[],
      recommendations?: ISearchPageAds[],
    ) => void,
    geoFilter?: GeoFilter,
  ) => void;
  fetchAdsV2: (
    filters: IGetAdsRequestFilter[],
    ranges: IGetAdsRequestRange[],
    makeModel: MakeModelType,
    from: number,
    pageSize: number,
    section: string,
    search: string,
    sort: string,
    userId: string | string[] | undefined,
    onStart: () => void,
    onSuccess: (
      breadcrumbs: SearchPageBreadcrumbs[],
      recommendations?: ISearchPageAds[],
    ) => void,
    geoFilter?: GeoFilter,
  ) => void;
  combineAds: (ads?: AdsType, spotlightAds?: AdsType) => ISearchPageAds[];
  combineAdsV2: (ads?: Ad[], spotlightAds?: Ad[]) => Ad[];
};

const useAds = (
  defaultAds: ISearchPageAds[] = [],
  defaultAdsV2: Ad[] = [],
  defaultSpotlights: ISearchPageAds[] = [],
  defaultSpotlightsV2: Ad[] = [],
  defaultSeoData: ISearchPageSeoData | undefined,
  defaultDFP: IDFP,
  defaultGTM: GTMEvent,
  setPaging: (paging: ISearchPagePaging) => void,
): UseAdsType => {
  const [state, dispatch] = useReducer(useAdsReducer, {
    isLoading: false,
    ads: defaultAds,
    adsV2: defaultAdsV2,
    spotlights: defaultSpotlights,
    spotlightsV2: defaultSpotlightsV2,
    error: null,
    seoData: defaultSeoData,
    dfp: defaultDFP,
    gtm: defaultGTM,
  });

  const setLoading = (loading: boolean) =>
    dispatch({
      type: ACTION_TYPE.SET_LOADING,
      payload: loading,
    });

  const setAds = (
    ads: AdsType | undefined,
    adsV2: Ad[] | undefined,
    spotlights: AdsType | undefined,
    spotlightsV2: Ad[] | undefined,
    seoData: SeoDataType | undefined,
    dfp: IDFP,
    gtm: GTMEvent,
  ) =>
    dispatch({
      type: ACTION_TYPE.SET_ADS,
      payload: {
        ads: ads as AdsType,
        adsV2: adsV2 as Ad[],
        spotlights: spotlights as AdsType,
        spotlightsV2: spotlightsV2 as Ad[],
        seoData: seoData as SeoDataType,
        dfp,
        gtm,
      },
    });

  const setError = (error: ErrorType) =>
    dispatch({
      type: ACTION_TYPE.SET_ERROR,
      payload: error,
    });

  const fetchAds = async (
    filters: IGetAdsRequestFilter[],
    ranges: IGetAdsRequestRange[],
    makeModel: MakeModelType,
    from: number,
    pageSize: number,
    section: string,
    search: string,
    sort: string,
    userId: string | string[] | undefined,
    onStart: () => void,
    onSuccess: (
      breadcrumbs: SearchPageBreadcrumbs[],
      recommendations?: ISearchPageAds[],
    ) => void,
    geoFilter: GeoFilter,
  ) => {
    setLoading(true);
    onStart();
    const editedFilters = [...filters];

    if (userId) {
      Array.isArray(userId)
        ? editedFilters.push({
            name: 'userId',
            values: userId,
          })
        : editedFilters.push({
            name: 'userId',
            values: [userId],
          });
    }

    const mappedFilterValues = mapLegacyAreaFilterValues(
      editedFilters,
      geoFilter?.county,
      geoFilter?.countyTown,
    );

    const getAdsPayload: IGetAdsRequest = {
      geoFilter: mapGeoFilterRequest(geoFilter),
      filters: mapFiltersRequest(mappedFilterValues),
      ...(ranges.length && { ranges: mapRangesRequest(ranges) }),
      ...(makeModel.length && {
        makeModelFilters: mapMakeModelFilters(makeModel),
      }),
      paging: {
        pageSize,
        from,
      },
      sections: [section],
      terms: search,
      sort,
    };

    try {
      const response = await searchPageApi.getAds(
        getAdsPayload,
        { accept: 'application/json' },
        API_CLIENT_TIMEOUT,
      );
      const {
        ads,
        spotlights,
        paging,
        seoData,
        dataTargetingProps: { dfp, gtm },
        breadcrumbs,
        recommendations,
      } = response.data;
      setAds(ads, [], spotlights, [], seoData, dfp, gtm);
      setPaging(paging);
      onSuccess(breadcrumbs, recommendations);
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling getAds'),
        tags: [PAGE.SEARCH],
        customData: {
          message: error.message || 'client_error',
          payload: getAdsPayload,
        },
      });
      setError(error);
    }
  };

  const fetchAdsV2 = async (
    filters: IGetAdsRequestFilter[],
    ranges: IGetAdsRequestRange[],
    makeModel: MakeModelType,
    from: number,
    pageSize: number,
    section: string,
    search: string,
    sort: string,
    userId: string | string[] | undefined,
    onStart: () => void,
    onSuccess: (
      breadcrumbs: SearchPageBreadcrumbs[],
      recommendations?: ISearchPageAds[],
    ) => void,
    geoFilter: GeoFilter,
  ) => {
    setLoading(true);
    onStart();
    const editedFilters = [...filters];

    if (userId) {
      Array.isArray(userId)
        ? editedFilters.push({
            name: 'userId',
            values: userId,
          })
        : editedFilters.push({
            name: 'userId',
            values: [userId],
          });
    }

    const mappedFilterValues = mapLegacyAreaFilterValues(
      editedFilters,
      geoFilter?.county,
      geoFilter?.countyTown,
    );

    const getAdsPayload: IGetAdsRequest = {
      geoFilter: mapGeoFilterRequest(geoFilter),
      filters: mapFiltersRequest(mappedFilterValues),
      ...(ranges.length && { ranges: mapRangesRequest(ranges) }),
      ...(makeModel.length && {
        makeModelFilters: mapMakeModelFilters(makeModel),
      }),
      paging: {
        pageSize,
        from,
      },
      sections: [section],
      terms: search,
      sort,
    };

    try {
      const response = await searchPageApi.getAdsV2(
        getAdsPayload,
        { accept: 'application/json' },
        API_CLIENT_TIMEOUT,
      );
      const {
        ads,
        spotlights,
        paging,
        seoData,
        dataTargetingProps: { dfp, gtm },
        breadcrumbs,
        recommendations,
      } = response.data;
      setAds([], ads, [], spotlights, seoData, dfp, gtm);
      setPaging(paging);
      onSuccess(breadcrumbs, recommendations);
    } catch (error) {
      rg4js('send', {
        error: new Error('Error handling getAdsV2'),
        tags: [PAGE.SEARCH],
        customData: {
          message: error.message || 'client_error',
          payload: getAdsPayload,
        },
      });
      setError(error);
    }
  };

  const mapSpotlightAds: (s?: AdsType) => ISearchPageAds[] = (spotlights) => {
    const mappedSpotlights: ISearchPageAds[] = [];
    spotlights &&
      spotlights.forEach((spotlight: ISearchPageAds) => {
        mappedSpotlights.push({
          ...spotlight,
          highlight: 'ORANGE',
          highlightText: 'SPOTLIGHT',
        });
      });
    return mappedSpotlights;
  };

  const combineAds = (ads?: AdsType, spotlightAds?: AdsType) => {
    let combinedAds = [
      ...(mapSpotlightAds(spotlightAds) || []),
      ...(ads || []),
    ];
    return combinedAds;
  };

  const combineAdsV2 = (ads: Ad[] = [], spotlightAds: Ad[] = []) => {
    return [...spotlightAds, ...ads];
  };

  return {
    ...state,
    fetchAds,
    fetchAdsV2,
    setLoading,
    setAds,
    setError,
    combineAds,
    combineAdsV2,
  };
};

export { useAds };
