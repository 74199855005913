import type { AxiosInstance, AxiosResponse } from 'axios';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { ENVIRONMENT_NAME },
} = getConfig();

export interface AxiosResponseWithTimer<T> extends AxiosResponse<T> {
  headers: {
    duration: number;
  };
}

/**
 * Delays response of axios request to take a min amount of time.
 * Expects to be used in combination withDuration to access `duration` in headers
 * @param instance
 * @param time
 */
export function withMinDuration(instance: AxiosInstance, time: number) {
  const newInstance = { ...instance };
  newInstance.interceptors.response.use((response) => {
    if (response.headers.duration) {
      const timeLeft = time - response.headers.duration;
      if (timeLeft) {
        if (ENVIRONMENT_NAME !== 'production') {
          console.log({
            request: response.request.responseURL,
            delayed: `${timeLeft}ms`,
          });
        }
        return new Promise((resolve) =>
          setTimeout(() => resolve(response), timeLeft),
        );
      }
    } else {
      if (ENVIRONMENT_NAME !== 'production') {
        console.error(
          'withMinDuration middleware expects a duration to be in the headers.',
        );
        throw new Error();
      }
    }
    return response;
  });

  return newInstance as AxiosInstance;
}

/**
 * Adds the duration for request to the response headers
 * @param instance
 */
export function withDuration(instance: AxiosInstance): AxiosInstance {
  const newInstance = { ...instance };
  newInstance.interceptors.request.use((config) => {
    config.headers['request-startTime'] = new Date().getTime();
    return config;
  });

  newInstance.interceptors.response.use((response) => {
    const currentTime = new Date().getTime();
    const startTime = response.config.headers['request-startTime'];
    const duration = currentTime - startTime;
    response.headers.duration = duration;
    if (ENVIRONMENT_NAME !== 'production') {
      console.log({
        request: response.request.responseURL,
        duration: `${duration}ms`,
      });
    }
    return response;
  });

  return newInstance as AxiosInstance;
}

const middleware = { withDuration };

export default middleware;
